import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'react-final-form';

import { EntityHeader } from '../components';
import { useItem } from '../services/useItem';
import { Event as EventType } from './EventTypes';
import { EventForm } from './EventForm';

export const Event: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<EventType>(match.params.id, '/events', history);
  const { found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={() => 'Событие'}
        listLabel="События"
        listUrl="/events"
      />

      {found && (
        <Form initialValues={item} onSubmit={save} render={EventForm} />
      )}
    </>
  );
};
