import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader, Paginator } from '../components';
import { Guest as GuestType } from './GuestTypes';
import { useItems } from '../services/useItems';

export const Guests: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<GuestType>('/guests', history, location);
  const { found, data, onPageClick, onRowClick, page, pageCount } = list;

  return (
    <>
      <EntityListHeader data={list} title="Гости" onPageClick={onPageClick} />

      {found && (
        <table className="table table-hover table-list">
          <thead>
            <tr>
              <th>Фамилия</th>
              <th>Имя</th>
              <th>Телефон</th>
              <th>Эл. почта</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr key={i.id} onClick={onRowClick}>
                <td>
                  <Link to={`/Guests/${i.id}`}>{i.last_name}</Link>
                </td>
                <td>{i.first_name}</td>
                <td>{i.phone}</td>
                <td>{i.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="float-right">
        {pageCount > 1 && (
          <Paginator
            page={page}
            onPageClick={onPageClick}
            pageCount={pageCount}
          />
        )}
      </div>
    </>
  );
};
