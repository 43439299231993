import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AxiosError } from 'axios';

import { api } from './api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

export interface AuthUser {
  id: number;
  avatar: string;
  name: string;
  role: string;
  impersonator?: {
    name: string;
  };
}

export interface AuthContextData {
  admin: boolean;
  deImpersonate: () => void;
  error?: string;
  impersonate: (id: number) => void;
  loaded: boolean;
  login: (username: string, password: string) => void;
  loginError?: string;
  loginRefreshing: boolean;
  logout: () => void;
  refreshAuth: () => void;
  refreshing: boolean;
  setUser: (user: AuthUser) => void;
  user: AuthUser | undefined;
}

const initialAuthData: AuthContextData = {
  admin: false,
  deImpersonate: () => {},
  impersonate: () => {},
  loaded: false,
  login: () => {},
  loginRefreshing: false,
  logout: () => {},
  refreshAuth: () => {},
  refreshing: false,
  setUser: () => {},
  user: undefined,
};

export const AuthContext = createContext<AuthContextData>(initialAuthData);

export const useAuth = (): AuthContextData => useContext(AuthContext);

export const useAuthData = (): AuthContextData => {
  let history = useHistory();

  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [loginRefreshing, setLoginRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshAuth = useCallback(() => {
    setRefreshCounter((v) => v + 1);
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        api
          .get<AuthUser | undefined>('/auth')
          .then((res) => {
            setUser(res.data);
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 401) {
              setUser(undefined);
            } else {
              setError(err.message);
            }
          })
          .then(() => {
            setRefreshing(false);
          });
      },
      process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 300 : 0
    );
  }, [refreshCounter]);

  const login = useCallback((login: string, password: string) => {
    setLoginError(undefined);
    setLoginRefreshing(true);
    api
      .post<AuthUser>('/auth/login', { login, password })
      .then((res) => {
        setLoginRefreshing(false);
        setUser(res.data);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 403) {
          setLoginError('Неверный логин или пароль');
        } else {
          setLoginError(
            err.response && err.response.data && err.response.data.error
          );
        }
        setLoginRefreshing(false);
      });
  }, []);

  const logout = useCallback(() => {
    setRefreshing(true);
    api
      .post('/auth/logout')
      .then(() => {
        refreshAuth();
      })
      .catch((err) => {
        toast.error(err.message);
      });
    history.push('/');
  }, [history, refreshAuth]);

  const impersonate = useCallback(
    (id) => {
      api
        .post<AuthUser>(`/auth/impersonate/${id}`)
        .then((res) => {
          setUser(res.data);
          history.push('/');
        })
        .catch((err: AxiosError) => {
          toast.error(err.message);
        });
    },
    [history]
  );

  const deImpersonate = useCallback(() => {
    api
      .delete<AuthUser>('/auth/impersonate')
      .then((res) => {
        setUser(res.data);
      })
      .catch((err: AxiosError) => {
        toast.error(err.message);
      });
  }, []);

  return useMemo<AuthContextData>(
    () => ({
      admin: user ? user.role === 'admin' : false,
      deImpersonate,
      error,
      impersonate,
      loaded: !refreshing,
      login,
      loginError,
      loginRefreshing,
      logout,
      manager: user ? user.role === 'admin' || user.role === 'manager' : false,
      refreshAuth,
      refreshing,
      setUser,
      student: user ? user.role === 'student' : false,
      teacher: user ? user.role === 'teacher' : false,
      user,
    }),
    [
      deImpersonate,
      error,
      impersonate,
      login,
      loginError,
      loginRefreshing,
      logout,
      refreshAuth,
      refreshing,
      user,
    ]
  );
};
