import React, { useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';

import { getFullNameNicer, wordAtNumber } from '../utils/format';
import { Guest } from '../guests/GuestTypes';

interface CallModalProps {
  guest?: Guest;
  onCancel: () => void;
  onCancelKid: (id: number) => void;
  onClose: () => void;
  onConfirm: () => void;
  onConfirmKid: (id: number) => void;
}

export const MeetModal: React.FC<CallModalProps> = ({
  guest,
  onCancel,
  onCancelKid,
  onClose,
  onConfirm,
  onConfirmKid,
}) => {
  const cancelKidClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      onCancelKid(id);
    },
    [onCancelKid]
  );

  const confirmKidClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      onConfirmKid(id);
    },
    [onConfirmKid]
  );

  return (
    <Modal
      isOpen={!!guest}
      toggle={onClose}
      fade={false}
      className="dialog-meet"
    >
      <ModalHeader toggle={onClose}>
        {guest && getFullNameNicer(guest)}
      </ModalHeader>
      <ModalBody>
        {guest && (
          <>
            {guest.note && (
              <div className="mb-3">
                <em>{guest.note}</em>
              </div>
            )}
            {guest.first_time && (
              <div className="mb-3">Первый раз в церкви «Слово жизни»</div>
            )}
            <button
              className={classnames(
                'btn',
                'btn-block',
                guest.checked_at ? 'btn-outline-danger' : 'btn-outline-success',
                'btn-lg'
              )}
              onClick={guest.checked_at ? onCancel : onConfirm}
            >
              {guest.checked_at ? 'Разотметить' : 'Отметить'}
            </button>
            {guest.kids.length > 0 &&
              guest.kids.map((k) => (
                <div
                  key={k.id}
                  className="mt-4 d-flex flex-row align-items-center"
                >
                  <div className="flex-grow-1">
                    Ребёнок {k.age} {wordAtNumber(k.age, 'год', 'года', 'лет')}{' '}
                  </div>
                  <button
                    className={classnames(
                      'btn',
                      'flex-grow-1',
                      k.checked_at
                        ? 'btn-outline-danger'
                        : 'btn-outline-success',
                      'btn-lg'
                    )}
                    onClick={k.checked_at ? cancelKidClick : confirmKidClick}
                    data-id={k.id}
                  >
                    {k.checked_at ? 'Разотметить' : 'Отметить'}
                  </button>
                </div>
              ))}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-secondary" onClick={onClose}>
          Закрыть
        </button>
      </ModalFooter>
    </Modal>
  );
};
