import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';

import { Event } from '../events/EventTypes';
import { getFullNameNicer, fixPhone } from '../utils/format';
import { Guest } from '../guests/GuestTypes';

interface CallModalProps {
  event?: Event;
  guest?: Guest;
  hasNext: boolean;
  hasPrev: boolean;
  index: number;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  onNext: () => void;
  onNote: (id: number, note: string) => void;
  onPrev: () => void;
  total: number;
}

export const CallModal: React.FC<CallModalProps> = ({
  event,
  guest,
  hasNext,
  hasPrev,
  index,
  onCancel,
  onClose,
  onConfirm,
  onNext,
  onNote,
  onPrev,
  total,
}) => {
  const isOpen = !!guest;
  const [saved, setSaved] = useState(false);

  const timeoutRef = useRef<Record<number, NodeJS.Timeout>>({});
  const [note, setNote] = useState('');

  const prevId = useRef<number | undefined>();

  useEffect(() => {
    if (prevId.current !== guest?.id) {
      prevId.current = guest?.id;
      setNote(guest?.note || '');
    }
  }, [guest]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setNote(value);
      if (guest) {
        if (timeoutRef.current[guest.id]) {
          clearTimeout(timeoutRef.current[guest.id]);
        }
        timeoutRef.current[guest.id] = setTimeout(() => {
          onNote(guest.id, value);
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 2000);
        }, 1000);
      }
    },
    [guest, onNote]
  );

  return (
    <Modal isOpen={isOpen} toggle={onClose} fade={false}>
      <ModalHeader toggle={onClose}>
        {guest && getFullNameNicer(guest)}
      </ModalHeader>
      <ModalBody>
        {guest && (
          <>
            <div className="text-muted">
              {event?.starts_at.format('dddd DD.MM.YYYY HH:mm')}
            </div>
            <h4 className="mt-4 mb-5 text-center">
              <a href={`tel:${fixPhone(guest.phone)}`}>{guest.phone}</a>
            </h4>

            <div className="d-flex flex-row my-4">
              <button
                className={classnames(
                  'btn',
                  guest.confirmed_at ? 'btn-success' : 'btn-outline-success',
                  'btn-lg'
                )}
                onClick={onConfirm}
              >
                Придет
              </button>
              <button
                className={classnames(
                  'btn',
                  guest.cancelled_at
                    ? 'btn-secondary'
                    : 'btn-outline-secondary',
                  'btn-lg',
                  'ml-auto'
                )}
                onClick={onCancel}
              >
                Не придет
              </button>
            </div>

            <textarea
              className="form-control"
              onChange={onInputChange}
              placeholder="Примечание"
              rows={4}
              value={note}
            />

            <div
              className="text-muted small"
              style={{
                position: 'relative',
                top: '0.375rem',
                opacity: saved ? 1 : 0,
                transition: 'opacity 0.2s ease',
              }}
            >
              Сохранено
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-outline-secondary"
          onClick={onPrev}
          disabled={!hasPrev}
        >
          <FaChevronLeft /> Назад
        </button>
        <div className="small text-muted text-center ml-auto mr-auto">
          {index} / {total}
        </div>
        <button
          className="btn btn-outline-secondary"
          onClick={onNext}
          disabled={!hasNext}
        >
          Вперед <FaChevronRight />
        </button>
      </ModalFooter>
    </Modal>
  );
};
