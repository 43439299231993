import React, { useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { api, catchToast, useAPI } from '../services/api';
import { Event, FormDataEvent } from '../events/EventTypes';
import { EventSelect } from '../call/EventSelect';
import { getFullName } from '../utils/format';
import { Guest } from '../guests/GuestTypes';
import { MeetModal } from './MeetModal';
import { SearchInput } from '../components/SearchInput';
import { useGuests } from '../call/useGuests';
import { useParseInt } from '../utils/usedParseInt';
import { useSearch } from '../utils/useSearch';
import { ErrorAlert } from '../components';

export const Meet: React.FC<RouteComponentProps<{ eventId: string }>> = ({
  match,
}) => {
  const events = useAPI<Event[]>('/events/today');
  const formDataEvents = useAPI<FormDataEvent[]>('/form-data');
  const eventId = useParseInt(match.params.eventId);

  const items = useMemo(
    () =>
      events.data && formDataEvents.data
        ? [...events.data, ...formDataEvents.data]
        : undefined,
    [events.data, formDataEvents.data]
  );

  const {
    guests: data,
    updateGuest,
    setGuestId,
    guest,
    refreshing: guestsRefreshing,
  } = useGuests(eventId);

  const {
    items: guests,
    unfiltered: sorted,
    ...search
  } = useSearch({
    items: data || [],
    toSearchString: (r) =>
      getFullName(r).toLocaleLowerCase() +
      ' ' +
      r.phone.replace(/[^\d]+/g, '') +
      ' ' +
      (r.note ? r.note.toLocaleLowerCase() : '') +
      ' ' +
      (r.email ? r.email.toLocaleLowerCase() : ''),
    filter: (r) => !r.cancelled_at,
    sort: true,
  });

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      setGuestId(id);
    },
    [setGuestId]
  );

  const onClose = useCallback(() => setGuestId(undefined), [setGuestId]);
  const onConfirm = useCallback(() => {
    if (guest) {
      api
        .post<Guest>(
          guest.id > 0
            ? guest.apiId
              ? `/guests/${guest.apiId}/check-teen/${eventId}`
              : `/guests/${guest.id}/check`
            : `/form-data/${guest.id}/check`
        )
        .then((res) => {
          updateGuest(res.data, guest.id);
          if (res.data.kids.length === 0) {
            setGuestId(undefined);
          }
        })
        .catch(catchToast);
    }
  }, [eventId, guest, setGuestId, updateGuest]);
  const onCancel = useCallback(() => {
    if (guest) {
      api
        .post<Guest>(
          guest.id > 0
            ? `/guests/${guest.id}/uncheck`
            : `/form-data/${guest.id}/uncheck`
        )
        .then((res) => {
          updateGuest(res.data);
          if (res.data.kids.length === 0) {
            setGuestId(undefined);
          }
        })
        .catch(catchToast);
    }
  }, [guest, setGuestId, updateGuest]);
  const onConfirmKid = useCallback(
    (id: number) => {
      api
        .post<Guest>(`/guests/kids/${id}/check`)
        .then((res) => {
          updateGuest(res.data);
          if (res.data.kids.length === 0) {
            setGuestId(undefined);
          }
        })
        .catch(catchToast);
    },
    [setGuestId, updateGuest]
  );
  const onCancelKid = useCallback(
    (id: number) => {
      api
        .post<Guest>(`/guests/kids/${id}/uncheck`)
        .then((res) => {
          updateGuest(res.data);
          if (res.data.kids.length === 0) {
            setGuestId(undefined);
          }
        })
        .catch(catchToast);
    },
    [setGuestId, updateGuest]
  );

  return (
    <>
      <MeetModal
        guest={guest}
        onCancel={onCancel}
        onCancelKid={onCancelKid}
        onClose={onClose}
        onConfirm={onConfirm}
        onConfirmKid={onConfirmKid}
      />

      <div className="d-flex flex-column mb-4">
        <h2>Встреча</h2>
        {items && (
          <EventSelect eventId={eventId} events={items} urlPrefix="/meet" />
        )}
      </div>

      <ErrorAlert>{events.error}</ErrorAlert>
      <ErrorAlert>{formDataEvents.error}</ErrorAlert>

      {!eventId && <div>Выберите собрание</div>}

      {eventId && sorted.length > 0 && (
        <SearchInput
          onChange={search.onChange}
          onClear={search.onClear}
          value={search.value}
        />
      )}

      {eventId &&
        guests.length === 0 &&
        (guestsRefreshing ? (
          <Spinner color="secondary" />
        ) : (
          <p>Регистрации не найдены</p>
        ))}
      {eventId && guests.length > 0 && (
        <>
          <div className="table-responsive d-none d-sm-block">
            <table
              className="table table-hover table-call"
              style={{ width: 'auto' }}
            >
              <thead>
                <tr>
                  {eventId > 0 && <th>Фамилия</th>}
                  <th>Имя</th>
                  <th>Телефон</th>
                  <th>Эл. почта</th>
                  <th>Дата рождения</th>
                </tr>
              </thead>
              <tbody>
                {guests.slice(0, 40).map((g) => (
                  <tr
                    key={g.id}
                    data-id={g.id}
                    onClick={onRowClick}
                    style={{
                      textDecoration: g.checked_at ? 'line-through' : undefined,
                    }}
                  >
                    {eventId > 0 && <td>{g.last_name}</td>}
                    <td>{g.first_name}</td>
                    <td>{g.phone}</td>
                    <td>{g.email}</td>
                    <td>{g.born_at && g.born_at.format('DD.MM.YYYY')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-block d-sm-none">
            {guests.slice(0, 40).map((g) => (
              <div
                key={g.id}
                className="py-3 call-person d-flex flex-column"
                data-id={g.id}
                onClick={onRowClick}
                style={{
                  textDecoration: g.checked_at ? 'line-through' : undefined,
                }}
              >
                <div className="d-flex flex-row align-items-center">
                  <div>
                    {getFullName(g)}
                    <br />
                    <small className="text-muted">
                      {g.born_at && g.born_at.format('DD.MM.YYYY')}
                    </small>
                  </div>
                  <small className="ml-auto text-right">
                    {g.phone}
                    <div className="text-muted">{g.email}</div>
                  </small>
                </div>
                {g.note && <em>{g.note}</em>}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
