import { Event, FormDataEvent } from './EventTypes';

function isEvent(e: Event | FormDataEvent): e is Event {
  return (e as Event).starts_at !== undefined;
}

export const shortEventName = (e: Event | FormDataEvent): string => {
  if (!isEvent(e)) {
    return e.title;
  }
  let name = e.starts_at.format('dd HH:mm');
  const teens = e.title.indexOf('Подростковое') !== -1;
  if (teens) {
    name += ' teens';
  }
  return name;
};
