import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface SearchInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  value: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  onClear,
  value,
}) => {
  return (
    <>
      <div
        className="py-3"
        style={{
          backgroundColor: '#fff',
          position: 'sticky',
          top: 0,
        }}
      >
        <div className="input-group" style={{ maxWidth: 400 }}>
          <input
            type="text"
            className="form-control"
            onChange={onChange}
            placeholder="Поиск"
            value={value}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              disabled={!value}
              onClick={onClear}
              type="button"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
