import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import moment, { Moment } from 'moment';

const FORMAT = 'DD.MM.YYYY HH:mm';

type DateTimeInputProps = {
  active?: boolean;
  onChange: (v: Moment | undefined) => void;
  value: Moment | undefined;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  active = false,
  onChange,
  onFocus,
  onBlur,
  value,
  ...rest
}) => {
  const [local, setLocal] = useState('');
  const [localParsed, setLocalParsed] = useState<Moment | undefined>();

  useEffect(() => {
    setLocal(value ? value.format(FORMAT) : '');
    setLocalParsed(value);
  }, [value]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      setLocal(v);
      if (v) {
        const t = moment(v, FORMAT);
        setLocalParsed(t || undefined);
        if (t.format(FORMAT) === v) {
          onChange(t);
        }
      } else {
        onChange(undefined);
        setLocalParsed(undefined);
      }
    },
    [onChange]
  );

  const isInvalid = useMemo(() => {
    if ((!local && !localParsed) || active) {
      return false;
    }
    return !localParsed || localParsed.format(FORMAT) !== local;
  }, [active, local, localParsed]);

  const isValid = useMemo(
    () =>
      active && local && localParsed && localParsed.format(FORMAT) === local,
    [active, local, localParsed]
  );

  return (
    <InputMask
      className={classnames(
        'form-control',
        isInvalid && 'is-invalid',
        isValid && 'is-valid'
      )}
      mask="99.99.9999 99:99"
      value={local}
      onChange={onInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      {...rest}
    />
  );
};
