import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { api, catchToast } from '../services/api';
import { getFullName } from '../utils/format';
import { MeetStaffModal } from './MeetStaffModal';
import { Staff } from './StaffTypes';

export const MeetStaff: React.FC = () => {
  const [data, setData] = useState<Staff[] | undefined>();
  const [guestId, setGuestId] = useState<number | undefined>();
  const guest = useMemo(
    () => (data && guestId ? data.find((i) => i.id === guestId) : undefined),
    [data, guestId]
  );
  const updateGuest = useCallback(
    (row: Staff) =>
      setData((v) => v && v.map((i) => (i.id === row.id ? row : i))),
    []
  );

  useEffect(() => {
    api
      .get<Staff[]>('/staff/today')
      .then((res) => setData(res.data))
      .catch(catchToast);
  }, []);

  const [search, setSearch] = useState('');
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );
  const onSearchClear = useCallback(() => {
    setSearch('');
  }, []);

  const sorted = useMemo(() => {
    const copy = [...(data || [])].map((r) => ({
      ...r,
      forSort:
        getFullName(r).toLocaleLowerCase() +
        ' ' +
        r.phone.replace(/[^\d]+/g, ''),
    }));
    copy.sort((a, b) => a.forSort.localeCompare(b.forSort));
    return copy;
  }, [data]);

  const guests = useMemo(() => {
    if (!search) {
      return sorted;
    }
    const words = search.toLocaleLowerCase().split(/s+/).filter(Boolean);
    return sorted.filter((r) =>
      words.every((w) => r.forSort.indexOf(w) !== -1)
    );
  }, [search, sorted]);

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      setGuestId(id);
    },
    [setGuestId]
  );

  const onClose = useCallback(() => setGuestId(undefined), [setGuestId]);
  const onConfirm = useCallback(() => {
    if (guest) {
      api
        .post<Staff>(`/staff/${guest.id}/check`)
        .then((res) => {
          updateGuest(res.data);
          setGuestId(undefined);
          toast.success('Отметка сохранена');
        })
        .catch(catchToast);
    }
  }, [guest, setGuestId, updateGuest]);
  const onCancel = useCallback(() => {
    if (guest) {
      api
        .post<Staff>(`/staff/${guest.id}/uncheck`)
        .then((res) => {
          updateGuest(res.data);
          setGuestId(undefined);
          toast.success('Отметка удалена');
        })
        .catch(catchToast);
    }
  }, [guest, setGuestId, updateGuest]);

  return (
    <>
      <MeetStaffModal
        guest={guest}
        onCancel={onCancel}
        onClose={onClose}
        onConfirm={onConfirm}
      />

      <div className="d-flex flex-column">
        <h2>Служители</h2>
      </div>

      {sorted.length > 0 && (
        <div
          className="py-3"
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
          }}
        >
          <div className="input-group" style={{ maxWidth: 400 }}>
            <input
              type="text"
              className="form-control"
              onChange={onSearchChange}
              placeholder="Поиск"
              value={search}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                disabled={!search}
                onClick={onSearchClear}
              >
                <FaTimes />
              </button>
            </div>
          </div>
        </div>
      )}

      {guests.length === 0 && <p>Служители не найдены</p>}
      {guests.length > 0 && (
        <>
          <div className="table-responsive d-none d-sm-block">
            <table
              className="table table-hover table-call"
              style={{ width: 'auto' }}
            >
              <thead>
                <tr>
                  <th>Фамилия</th>
                  <th>Имя</th>
                  <th>Телефон</th>
                </tr>
              </thead>
              <tbody>
                {guests.map((g) => (
                  <tr
                    key={g.id}
                    data-id={g.id}
                    onClick={onRowClick}
                    style={{
                      textDecoration: g.met_at ? 'line-through' : undefined,
                    }}
                  >
                    <td>{g.last_name}</td>
                    <td>{g.first_name}</td>
                    <td>{g.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-block d-sm-none">
            {guests.map((g) => (
              <div
                key={g.id}
                className="py-3 call-person d-flex d-flex-row align-items-center"
                data-id={g.id}
                onClick={onRowClick}
                style={{
                  textDecoration: g.met_at ? 'line-through' : undefined,
                }}
              >
                {getFullName(g)}{' '}
                <small className="text-muted ml-auto">{g.phone}</small>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
