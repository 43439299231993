import React, { createContext, useContext } from 'react';

const FormContext = createContext({
  horizontal: true,
  inline: false,
});

export const useFormContext = () => useContext(FormContext);

interface FormProps {
  horizontal?: boolean;
  inline?: boolean;
}

export const Form: React.FC<FormProps & React.HTMLProps<HTMLFormElement>> = ({
  children,
  className,
  horizontal = false,
  inline = false,
  ...rest
}) => (
  <FormContext.Provider value={{ horizontal, inline }}>
    <form
      className={className ? className : inline ? 'form-inline' : undefined}
      {...rest}
    >
      {children}
    </form>
  </FormContext.Provider>
);
