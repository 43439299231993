import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'react-final-form';

import { EntityHeader } from '../components';
import { useItem } from '../services/useItem';
import { Guest as GuestType } from './GuestTypes';
import { GuestForm } from './GuestForm';

export const Guest: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<GuestType>(match.params.id, '/guests', history);
  const { found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        del={false}
        getTitle={() => 'Гость'}
        listLabel="Гости"
        listUrl="/guests"
      />

      {found && (
        <Form initialValues={item} onSubmit={save} render={GuestForm} />
      )}
    </>
  );
};
