import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppUser } from '../appUsers/AppUser';
import { AppUsers } from '../appUsers/AppUsers';
import { Call } from '../call/Call';
import { CenterSpinner, ErrorAlert, Header, Login } from './';
import { config } from '../config';
import { Dashboard } from '../dashboard/Dashboard';
import { Event } from '../events/Event';
import { Events } from '../events/Events';
import { Guest } from '../guests/Guest';
import { Guests } from '../guests/Guests';
import { Meet } from '../meet/Meet';
import { MeetStaff } from '../meet/MeetStaff';
import { useAuth } from '../services/auth';
import { User } from '../users/User';
import { Users } from '../users/Users';

export const App = () => {
  const {
    admin,
    error,
    loaded,
    login,
    loginError,
    loginRefreshing,
    user,
  } = useAuth();

  if (!loaded) {
    return <CenterSpinner />;
  }

  if (error) {
    return (
      <div className="container-fluid main-container my-3">
        <ErrorAlert>{error}</ErrorAlert>
      </div>
    );
  }

  if (!user) {
    return (
      <Login
        error={loginError}
        onSubmit={login}
        refreshing={loginRefreshing}
        title={config.app.name}
      />
    );
  }

  return (
    <>
      <ToastContainer position="bottom-right" />
      <Route component={Header} />
      <div className="container-fluid main-container pb-3">
        <Switch>
          {admin && <Route path="/users" exact component={Users} />}
          {admin && <Route path="/users/:id" exact component={User} />}

          {admin && <Route path="/app-users" exact component={AppUsers} />}
          {admin && <Route path="/app-users/:id" exact component={AppUser} />}

          {admin && <Route path="/events" exact component={Events} />}
          {admin && <Route path="/events/:id" exact component={Event} />}

          <Route path="/" exact component={Dashboard} />
          <Route path="/call/:eventId?" exact component={Call} />
          <Route path="/guests" exact component={Guests} />
          <Route path="/guests/:id" exact component={Guest} />
          <Route path="/staff" exact component={MeetStaff} />
          <Route path="/meet/:eventId?" exact component={Meet} />

          <Route>
            <ErrorAlert>Страница не найдена</ErrorAlert>
          </Route>
        </Switch>
      </div>
    </>
  );
};
