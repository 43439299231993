import { AppUser } from './AppUserTypes';

export const validateAppUser = (values: AppUser) => {
  const errors: { [key: string]: string } = {};

  if (!values.full_name) {
    errors.full_name = 'Нужно заполнить';
  }

  return errors;
};
