import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import classnames from 'classnames';

import { ErrorAlert, CenterSpinner } from '.';
import { Paginator } from './Paginator';
import { UseItemsResult } from '../services/useItems';

interface EntityListHeaderProps<T> {
  addLabel?: string;
  children?: React.ReactNode;
  createUrl?: string;
  data: UseItemsResult<T>;
  onPageClick?: (page: number) => void;
  title?: string;
}

export function EntityListHeader<T>({
  addLabel,
  children,
  createUrl,
  data,
  onPageClick,
  title,
}: EntityListHeaderProps<T>) {
  const { error, notFound, pageCount, refreshing, total } = data;

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center flex-wrap">
        {title && <h2>{title}</h2>}
        {!refreshing && !error && (
          <>
            <div className="text-muted ml-3">{total}</div>
            {children}
            <div
              className={classnames(
                !children && 'ml-auto',
                'pl-3',
                pageCount > 1 && 'pr-3'
              )}
            >
              {createUrl && (
                <Link
                  role="button"
                  to={createUrl}
                  className="btn btn-sm btn-secondary"
                >
                  <FaPlus /> {addLabel || 'Создать'}
                </Link>
              )}
            </div>
            {pageCount > 1 && onPageClick && (
              <Paginator
                className={!children && !createUrl ? 'ml-auto' : undefined}
                page={data.page}
                pageCount={data.pageCount}
                onPageClick={onPageClick}
              />
            )}
          </>
        )}
      </div>

      <ErrorAlert>{error}</ErrorAlert>

      {refreshing && <CenterSpinner />}

      {notFound && title && <div className="mt-3">{title} не найдены</div>}
    </div>
  );
}
