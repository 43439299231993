import { useCallback, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { History, Location } from 'history';

import { api } from './api';
import { useSearch } from './useSearch';

export interface List<T> {
  data: T[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface UseItemsResult<T> extends List<T> {
  error?: string;
  found: boolean;
  hasData: boolean;
  notFound: boolean;
  onPageClick: (page: number) => void;
  onRowClick: RowClickHandler;
  params: any;
  refresh: () => void;
  refreshing: boolean;
  setParams: React.Dispatch<React.SetStateAction<any>>;
}

interface UseItemsOptions {
  limit?: number;
}

export const useItems = <T>(
  path: string,
  history: History,
  location: Location,
  options?: UseItemsOptions
): UseItemsResult<T> => {
  const [resData, setResData] = useState<List<T> | undefined>(undefined);
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [params, setParams] = useSearch({ history, location });

  const onRowClick = useCallback(rowClick(history), [history]);

  const onPageClick = useCallback(
    (p: number) => {
      setParams((s: any) => ({ ...s, page: p === 1 ? undefined : p }));
    },
    [setParams]
  );

  const refresh = useCallback(() => {
    setRefreshTrigger((v) => v + 1);
  }, []);

  const paramsJSON = useMemo(() => {
    const base = { limit: options && options.limit };
    return JSON.stringify({ ...base, ...(params || {}) });
  }, [params, options]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let aborted = false;
    // setRefreshing(true);
    setError(undefined);
    api
      .get<List<T>>(path, {
        params: paramsJSON ? JSON.parse(paramsJSON) : undefined,
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        if (!aborted) {
          setResData(res.data);
        }
      })
      .catch((err) => {
        if (!aborted) {
          setError(err.message);
        }
      })
      .then(() => {
        if (!aborted) {
          setRefreshing(false);
        }
      });

    return () => {
      cancelToken.cancel();
      aborted = true;
    };
  }, [path, paramsJSON, refreshTrigger]);

  const { data, count, total, page, pageCount } = useMemo<List<T>>(
    () => ({
      data: resData ? resData.data : [],
      count: resData ? resData.count : 0,
      page: resData ? resData.page : 1,
      pageCount: resData ? resData.pageCount : 0,
      total: resData ? resData.total : 0,
    }),
    [resData]
  );

  return {
    count,
    data,
    error,
    found: !refreshing && !error && data.length > 0 ? true : false,
    hasData: !refreshing && !error,
    notFound: !refreshing && !error && data.length === 0 ? true : false,
    onPageClick,
    onRowClick,
    page,
    pageCount,
    params,
    refresh,
    refreshing,
    setParams,
    total,
  };
};

export type RowClickHandler = (
  e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
) => void;

export const rowClick = (history: History): RowClickHandler => (e) => {
  if (!(e.target instanceof Node)) {
    return;
  }
  const links = e.currentTarget.querySelectorAll('a');
  for (let i = 0; i < links.length; i += 1) {
    if (links[i].contains(e.target)) {
      return;
    }
  }
  if (links.length > 0) {
    const href = links[0].getAttribute('href');
    if (href) {
      history.push(href);
    }
  }
};
