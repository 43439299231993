import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import moment from 'moment';

import { DashboardEvent } from './DashboardTypes';

export const useDashboard = () => {
  const [events, setEvents] = useState<DashboardEvent[] | undefined>();
  const [staff, setStaff] = useState<Record<number, number> | undefined>();

  useEffect(() => {
    const s = io('/dashboard');
    s.on('events', (data: DashboardEvent[]) => {
      setEvents(data.map((r) => ({ ...r, startsAt: moment(r.startsAt) })));
    });
    s.on('staff', (data: Record<number, number>) => {
      setStaff(data);
    });
    return () => {
      s.close();
    };
  }, []);

  return { events, staff };
};
