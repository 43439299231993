import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames';

import { getFullNameNicer } from '../utils/format';
import { Staff } from './StaffTypes';

interface CallModalProps {
  guest?: Staff;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

export const MeetStaffModal: React.FC<CallModalProps> = ({
  guest,
  onCancel,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      isOpen={!!guest}
      toggle={onClose}
      fade={false}
      className="dialog-meet"
    >
      <ModalHeader toggle={onClose}>
        {guest && getFullNameNicer(guest)}
      </ModalHeader>
      <ModalBody>
        {guest && (
          <>
            <button
              className={classnames(
                'btn',
                'btn-block',
                guest.met_at ? 'btn-outline-danger' : 'btn-outline-success',
                'btn-lg'
              )}
              onClick={guest.met_at ? onCancel : onConfirm}
            >
              {guest.met_at ? 'Разотметить' : 'Отметить'}
            </button>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-secondary" onClick={onClose}>
          Закрыть
        </button>
      </ModalFooter>
    </Modal>
  );
};
