import { FieldMetaState } from 'react-final-form';

export const inputError = (meta: FieldMetaState<any>): string | undefined => {
  const error =
    (meta.visited && !meta.active) || meta.submitFailed
      ? meta.error
      : undefined;

  return error;
};
