import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Event } from './EventTypes';

interface CloneModalProps {
  events: Event[];
  onClose: () => void;
  onSubmit: (ids: number[]) => void;
}

export const CloneModal: React.FC<CloneModalProps> = ({
  events,
  onClose,
  onSubmit,
}) => {
  const [selected, setSelected] = useState(events.map((e) => e.id));

  const submit = useCallback(() => {
    onSubmit(selected);
  }, [onSubmit, selected]);

  const onSelect = useCallback(
    (id: number) => () => {
      setSelected((v) => {
        const checked = v.includes(id);
        if (checked) {
          return v.filter((i) => i !== id);
        }
        return [...v, id];
      });
    },
    []
  );

  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Копировать события на следующую неделю
      </ModalHeader>
      <ModalBody>
        {events.map((e) => (
          <div key={e.id}>
            <label>
              <input
                checked={selected.includes(e.id)}
                onChange={onSelect(e.id)}
                type="checkbox"
              />{' '}
              {e.starts_at.format('ddd HH:mm')} {e.title}
            </label>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submit}>
          Копировать
        </Button>{' '}
        <Button color="secondary" onClick={onClose}>
          Отмена
        </Button>
      </ModalFooter>
    </Modal>
  );
};
