import React from 'react';
import { FormRenderProps } from 'react-final-form';

import { Form, FormLimiter, FormSubmitContainer, Input } from '../components';
import { Guest as GuestType } from './GuestTypes';

export const GuestForm: React.FC<FormRenderProps<GuestType>> = ({
  handleSubmit,
  errors,
  submitting,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <FormLimiter>
      <Input name="last_name" maxLength={50} label="Фамилия" cols={6} />
      <Input name="first_name" maxLength={50} label="Имя" cols={6} />

      <Input name="phone" maxLength={20} label="Телефон" cols={3} />
      <Input name="email" maxLength={50} label="Эл. почта" cols={3} />
    </FormLimiter>

    <FormSubmitContainer>
      <button
        className="btn btn-lg btn-primary"
        type="submit"
        disabled={submitting}
      >
        Сохранить
      </button>
    </FormSubmitContainer>
  </Form>
);
