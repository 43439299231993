import React from 'react';
import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';

import { AppUser as AppUserType } from './AppUserTypes';
import { AppUserForm } from './AppUserForm';
import { EntityHeader } from '../components';
import { useItem } from '../services/useItem';
import { validateAppUser } from './validateAppUser';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaPlus, FaMobileAlt } from 'react-icons/fa';

export const AppUser: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<AppUserType>(match.params.id, '/app-users', history);
  const { found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={(user) => (
          <div className="d-flex d-flex-row">
            <div className="mr-3">Пользователь приложения</div>
            {user.id && (
              <div>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret outline>
                    <FaPlus />
                    <FaMobileAlt />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header>Отсканируйте</DropdownItem>
                    <DropdownItem>
                      <img
                        alt="QR-код для регистрации устройства"
                        src={`/api/app-users/${user.id}/register-device-qr-code.png`}
                      />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            )}
          </div>
        )}
        listLabel="Пользователи приложения"
        listUrl="/app-users"
      />

      {found && (
        <Form
          initialValues={item}
          onSubmit={save}
          render={AppUserForm}
          validate={validateAppUser}
        />
      )}
    </>
  );
};
