import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { Event, FormDataEvent } from '../events/EventTypes';
import { shortEventName } from '../events/shortEventName';

interface EventSelectProps {
  eventId?: number;
  events: (Event | FormDataEvent)[];
  urlPrefix: string;
}

export const EventSelect: React.FC<EventSelectProps> = ({
  eventId,
  events,
  urlPrefix,
}) => (
  <div className="mr-auto">
    <div className="btn-group d-none d-sm-flex">
      {events.map((e) => (
        <NavLink
          key={e.id}
          className={classnames(
            'btn',
            e.id === eventId ? 'btn-primary' : 'btn-outline-primary'
          )}
          to={`${urlPrefix}/${e.id}`}
        >
          {shortEventName(e)}
        </NavLink>
      ))}
    </div>
    <div className="btn-group d-flex d-sm-none">
      {events.map((e) => (
        <NavLink
          key={e.id}
          className={classnames(
            'btn',
            'btn-sm',
            e.id === eventId ? 'btn-primary' : 'btn-outline-primary'
          )}
          to={`${urlPrefix}/${e.id}`}
        >
          {shortEventName(e)}
        </NavLink>
      ))}
    </div>
  </div>
);
