import React from 'react';
import { FormRenderProps, Field } from 'react-final-form';

import {
  Form,
  FormGroup,
  FormLimiter,
  FormSubmitContainer,
  Input,
  Checkbox,
} from '../components';
import { DateTimeInput } from '../components/forms/DateTimeInput';
import { Event as EventType } from './EventTypes';
import { KidAgeInput } from './KidAgeInput';

export const EventForm: React.FC<FormRenderProps<EventType>> = ({
  handleSubmit,
  errors,
  submitting,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <FormLimiter>
      <Input name="title" maxLength={200} label="Название" cols={6} />
      <Field name="starts_at">
        {({ input, meta }) => (
          <FormGroup id="starts_at" label="Начало" cols={3}>
            <DateTimeInput active={meta.active} id="starts_at" {...input} />
          </FormGroup>
        )}
      </Field>
      <Field name="ends_at">
        {({ input, meta }) => (
          <FormGroup id="ends_at" label="Конец" cols={3}>
            <DateTimeInput active={meta.active} id="ends_at" {...input} />
          </FormGroup>
        )}
      </Field>
      <Input type="number" name="total_seats" label="Мест" cols={3} />
      <Field name="kids">
        {({ input }) => (
          <FormGroup label="Детей">
            <KidAgeInput {...input} />
          </FormGroup>
        )}
      </Field>
      <Checkbox name="show" label="Показывать" />
      <Checkbox name="qr_codes" label="QR-коды" />
      <Checkbox
        name="break_after"
        label="Последнее в группе. Вставить разрыв после"
      />
      <Input
        name="group_title"
        maxLength={100}
        label="Заголовок группы"
        cols={6}
      />
      <Input
        type="number"
        name="priority"
        label="Притяжение к началу"
        cols={1}
      />
    </FormLimiter>

    <FormSubmitContainer>
      <button
        className="btn btn-lg btn-primary"
        type="submit"
        disabled={submitting}
      >
        Сохранить
      </button>
    </FormSubmitContainer>
  </Form>
);
