import { useCallback, useMemo, useState } from 'react';

interface UseSearchOptions<T> {
  filter?: (item: T) => boolean;
  items: T[];
  sort?: boolean;
  toSearchString: (item: T) => string;
}

export function useSearch<T>({
  filter,
  items: data,
  sort,
  toSearchString,
}: UseSearchOptions<T>) {
  const [search, setSearch] = useState('');
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);
  const onClear = useCallback(() => {
    setSearch('');
  }, []);

  const sorted = useMemo(() => {
    let copy = [...(data || [])];
    if (filter) {
      copy = copy.filter(filter);
    }
    const withSearch = copy.map((r) => ({
      ...r,
      forSort: toSearchString(r),
    }));
    if (sort) {
      withSearch.sort((a, b) => a.forSort.localeCompare(b.forSort));
    }
    return withSearch;
  }, [data, filter, sort, toSearchString]);

  const filtered = useMemo(() => {
    if (!search) {
      return sorted;
    }
    const words = search.toLocaleLowerCase().split(/s+/).filter(Boolean);
    return sorted.filter((r) =>
      words.every((w) => r.forSort.indexOf(w) !== -1)
    );
  }, [search, sorted]);

  return {
    items: filtered,
    onChange,
    onClear,
    unfiltered: sorted,
    value: search,
  };
}
