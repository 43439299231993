import React, { useMemo } from 'react';
import moment from 'moment';

import { useDashboard } from './useDashboard';

export const Dashboard: React.FC = () => {
  const { events } = useDashboard();

  const intervalName = useMemo(() => {
    if (!events || events.length === 0) {
      return undefined;
    }
    const dates = events.map((e) => e.startsAt.valueOf());
    const min = moment(Math.min(...dates));
    const max = moment(Math.max(...dates));
    const minDD = min.format('DD');
    const maxDD = max.format('DD');

    return (
      <>
        Выходные {minDD !== maxDD ? `${minDD}–` : null}
        {max.format('DD MMMM')}
      </>
    );
  }, [events]);

  const kidEvents = (events || []).filter((e) => e.totalKids > 0);

  return (
    <div>
      {events && (
        <>
          <h2>{intervalName}</h2>
          <div className="table-responsive">
            <table className="table table-hover" style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th>
                    <span className="d-block d-sm-none">Время</span>
                    <span className="d-none d-sm-inline">Собрание</span>
                  </th>
                  <th className="text-right">Всего</th>
                  <th className="text-right">
                    <span className="d-block d-sm-none">Рег.</span>
                    <span className="d-none d-sm-inline">Зарегистрировано</span>
                  </th>
                  <th className="text-right">
                    <span className="d-block d-sm-none">Своб.</span>
                    <span className="d-none d-sm-inline">Осталось</span>
                  </th>
                  <th className="text-right">Пришло</th>
                </tr>
              </thead>
              <tbody>
                {events.map((i) => (
                  <tr key={i.id}>
                    <td>
                      {i.startsAt.format('dd HH:mm')}
                      {i.label ? ` ${i.label}` : ''}
                    </td>
                    <td className="text-right">{i.totalSeats}</td>
                    <td className="text-right">{i.totalSeats - i.freeSeats}</td>
                    <td className="text-right">{i.freeSeats}</td>
                    <td className="text-right">{i.arrived}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {kidEvents.length > 0 &&
        kidEvents.map((e) => (
          <>
            <h3>Дети {e.startsAt.format('dd HH:mm')}</h3>
            <table key={e.id} className="table" style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th>Возраст</th>
                  <th className="text-right">Кол-во</th>
                </tr>
              </thead>
              <tbody>
                {e.kids.map((age) => (
                  <tr key={age.id}>
                    <td>{age.title}</td>
                    <td className="text-right">{age.cnt}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Всего</th>
                  <th className="text-right">{e.totalKids}</th>
                </tr>
              </tfoot>
            </table>
          </>
        ))}
    </div>
  );
};
