import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';

import { AppUser as AppUserType } from './AppUserTypes';
import { EntityListHeader, Paginator } from '../components';
import { useItems } from '../services/useItems';

export const AppUsers: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<AppUserType>('/app-users', history, location);
  const { found, data, onPageClick, onRowClick, page, pageCount } = list;

  const now = moment();

  return (
    <>
      <EntityListHeader
        data={list}
        title="Пользователи приложения"
        createUrl="/app-users/create"
        onPageClick={onPageClick}
      />

      {found && (
        <div className="table-responsive">
          <table className="table table-hover table-list">
            <thead>
              <tr>
                <th>Имя</th>
                <th>Статус</th>
                <th>Изменен</th>
                <th>Устройства</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id} onClick={onRowClick}>
                  <td>
                    <Link to={`/app-users/${user.id}`}>{user.full_name}</Link>
                  </td>
                  <td>{user.disabled ? 'Выкл.' : 'Вкл.'}</td>
                  <td>{user.updated_at.fromNow()}</td>
                  <td>
                    {user.devices.map((d) => (
                      <span
                        key={d.id}
                        className={classnames(
                          'badge',
                          'mr-1',
                          d.lastUsedAt.isSame(now, 'day')
                            ? 'badge-success'
                            : 'badge-secondary'
                        )}
                        title={`${d.system} ${d.version}`}
                      >
                        {d.model}: {d.checkCount} скан. {d.lastUsedAt.fromNow()}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="float-right">
        {pageCount > 1 && (
          <Paginator
            page={page}
            onPageClick={onPageClick}
            pageCount={pageCount}
          />
        )}
      </div>
    </>
  );
};
