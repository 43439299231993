import React, { useCallback } from 'react';
import { nanoid } from 'nanoid';

import { EventKids, KidAge } from './EventTypes';
import { useAPI } from '../services/api';

interface KidAgeInputProps {
  onChange: (value: EventKids[]) => void;
  value?: EventKids[];
}

export const KidAgeInput: React.FC<KidAgeInputProps> = ({
  onChange,
  value,
}) => {
  const ages = useAPI<KidAge[]>('/events/kid-ages');

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      if (!Number.isNaN(id)) {
        const parsed = parseInt(e.target.value);
        const max_kids = Number.isNaN(parsed) ? 0 : parsed;
        const nextValue = (value || []).map((r) =>
          r.kid_age_id === id ? { ...r, max_kids } : r
        );
        if (!nextValue.find((r) => r.kid_age_id === id)) {
          nextValue.push({
            _id: nanoid(),
            max_kids,
            kid_age_id: id,
          });
        }
        onChange(nextValue);
      }
    },
    [onChange, value]
  );

  return (
    <>
      {(ages.data || []).map((a) => {
        const row = (value || []).find((r) => r.kid_age_id === a.id);
        return (
          <div key={a.id} className="row mb-2">
            <label
              className="col-sm-2 col-form-label"
              htmlFor={`kid_age_${a.id}`}
            >
              {a.title}
            </label>
            <div className="col-sm-2">
              <input
                onChange={onInputChange}
                data-id={a.id}
                type="number"
                className="form-control"
                id={`kid_age_${a.id}`}
                min={0}
                step={1}
                value={row?.max_kids || 0}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
