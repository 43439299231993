import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';

import { FormGroup } from './FormGroup';
import { inputError } from './errors';

interface InputProps {
  cols?: number;
  help?: string;
  label?: string;
  labelCols?: number;
  name: string;
  type?: string;
}

export const Input: React.FC<
  InputProps & React.HTMLProps<HTMLInputElement>
> = ({ cols = 10, help, label, labelCols = 2, name, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => {
      const error = inputError(meta);
      return (
        <FormGroup
          cols={cols}
          error={error}
          id={name}
          label={label}
          labelCols={labelCols}
        >
          <input
            className={classnames('form-control', error && 'is-invalid')}
            id={name}
            {...input}
            {...rest}
          />
          {error && <div className="invalid-feedback">{error}</div>}
          {help && <small className="form-text text-muted">{help}</small>}
        </FormGroup>
      );
    }}
  </Field>
);
