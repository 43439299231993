import { useState, useEffect, useCallback, useMemo } from 'react';

import { api, catchToast } from '../services/api';
import { Guest } from '../guests/GuestTypes';

export const useGuests = (eventId?: number) => {
  const [guests, setGuests] = useState<Guest[] | undefined>();
  const [guestId, setGuestId] = useState<number | undefined>();
  const [refreshing, setRefreshing] = useState(false);

  const index = useMemo(
    () => guests?.findIndex((g) => g.id === guestId),
    [guests, guestId]
  );
  const guest = useMemo(
    () => guests?.find((g) => g.id === guestId),
    [guests, guestId]
  );

  useEffect(() => {
    setGuests(undefined);
    setRefreshing(true);
    if (eventId) {
      api
        .get<Guest[]>(
          eventId > 0 ? `/guests/event/${eventId}` : `/form-data/${eventId}`
        )
        .then((res) => {
          setGuests(res.data);
        })
        .catch(catchToast)
        .then(() => {
          setRefreshing(false);
        });
    }
  }, [eventId]);

  const updateGuest = useCallback((value: Guest, oldId?: number) => {
    setGuests(
      (v) =>
        v &&
        v.map((g) => ((oldId ? g.id === oldId : g.id === value.id) ? value : g))
    );
  }, []);

  return { guest, guests, index, refreshing, setGuestId, updateGuest };
};
