import React, { useCallback, useMemo, useState } from 'react';
import { FaClone, FaDownload } from 'react-icons/fa';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';
import Calendar from 'react-calendar';
import qs from 'qs';

import { dateStrISO } from '../utils/format';
import { EntityListHeader } from '../components';
import { Event as EventType } from './EventTypes';
import { UpdateBotButton } from './UpdateBotButton';
import { api, catchToast, useAPI } from '../services/api';
import { useItems } from '../services/useItems';
import { CloneModal } from './CloneModal';

export const Events: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const count = useAPI<{ date: Moment; count: number }[]>('/events/count');
  const date = useMemo(() => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof search.date === 'string') {
      return new Date(search.date);
    }
    if (count.data && count.data.length > 0) {
      return count.data[count.data.length - 1].date.toDate();
    }
    return new Date();
  }, [count, location.search]);

  const dateRange = useMemo(() => {
    const start = new Date(date.getTime());
    const day = start.getDay();
    start.setDate(start.getDate() - day + (day === 0 ? -6 : 1));
    const end = new Date(start.getTime());
    end.setDate(end.getDate() + 6);
    return { start, end };
  }, [date]);

  const onClickDay = useCallback(
    (d: Date) => {
      history.push(`/events?date=${dateStrISO(d)}`);
    },
    [history]
  );

  const [showClone, setShowClone] = useState(false);
  const toggleClone = useCallback(() => setShowClone((v) => !v), []);
  const onClone = useCallback(
    (ids: number[]) => {
      api
        .post('/events/clone', { ids })
        .then(() => {
          history.push('/events');
          toggleClone();
        })
        .catch(catchToast);
    },
    [history, toggleClone]
  );

  const list = useItems<EventType>(
    `/events?from=${dateRange.start.toJSON()}&till=${dateRange.end.toJSON()}`,
    history,
    location
  );
  const { hasData, data, onRowClick } = list;

  return (
    <>
      {showClone && (
        <CloneModal events={data} onSubmit={onClone} onClose={toggleClone} />
      )}
      <EntityListHeader
        data={{ ...list, notFound: false }}
        title="События"
        createUrl="/events/create"
      >
        <div className="ml-auto mr-3">
          <UpdateBotButton />
        </div>
        <div className="mr-3">
          <button
            className="btn btn-outline-secondary"
            onClick={toggleClone}
            type="button"
          >
            <FaClone />
          </button>
        </div>
        <a
          className="btn btn-outline-secondary"
          href="/api/events/download"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaDownload />
        </a>
      </EntityListHeader>

      {hasData && (
        <div className="d-flex flex-column flex-sm-row">
          <div className="mr-3">
            <Calendar
              onClickDay={onClickDay}
              value={[dateRange.start, dateRange.end]}
            />
          </div>
          <div>
            {data.length === 0 ? (
              'События не найдены'
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-list">
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Начало</th>
                      <th>Дата</th>
                      <th className="text-right">Мест</th>
                      <th>Видно</th>
                      <th>QR-коды</th>
                      <th>Разрыв</th>
                      <th>Заголовок группы</th>
                      <th>
                        <FaDownload />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i) => (
                      <tr key={i.id} onClick={onRowClick}>
                        <td>
                          <Link
                            to={(l) => ({ ...l, pathname: `/events/${i.id}` })}
                          >
                            {i.title}
                          </Link>
                        </td>
                        <td>{i.starts_at.format('dd HH:mm')}</td>
                        <td>{i.starts_at.format('DD.MM')}</td>
                        <td className="text-right">{i.total_seats}</td>
                        <td>{i.show ? 'Да' : 'Нет'}</td>
                        <td>{i.qr_codes ? 'Да' : 'Нет'}</td>
                        <td>{i.break_after ? 'Да' : 'Нет'}</td>
                        <td>{i.group_title}</td>
                        <td>
                          <a href={`/api/events/download/${i.id}`}>...</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
