import React from 'react';
import { FormRenderProps } from 'react-final-form';

import {
  Checkbox,
  Form,
  FormLimiter,
  FormSubmitContainer,
  Input,
  Select,
} from '../components';
import { User as UserType, roles } from './UserTypes';

export const UserForm: React.FC<FormRenderProps<UserType>> = ({
  handleSubmit,
  errors,
  submitting,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <FormLimiter>
      <Input name="full_name" label="Полное имя" cols={6} />
      <Input name="email" label="Эл. почта" cols={4} />
      <Input name="login" label="Логин" cols={4} />
      <Input name="password" label="Пароль" type="password" cols={3} />
      <Select
        name="role"
        label="Роль"
        options={{ '': '', ...roles }}
        cols={3}
      />
      <Checkbox name="disabled" label="Заблокирован" />
    </FormLimiter>

    <FormSubmitContainer>
      <button
        className="btn btn-lg btn-primary"
        type="submit"
        disabled={submitting}
      >
        Сохранить
      </button>
    </FormSubmitContainer>
  </Form>
);
