import React from 'react';
import { FormRenderProps } from 'react-final-form';

import { AppUser as AppUserType } from './AppUserTypes';
import {
  Checkbox,
  Form,
  FormLimiter,
  FormSubmitContainer,
  Input,
} from '../components';

export const AppUserForm: React.FC<FormRenderProps<AppUserType>> = ({
  handleSubmit,
  errors,
  submitting,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <FormLimiter>
      <Input name="full_name" label="Полное имя" cols={6} />
      <Checkbox name="disabled" label="Заблокирован" />
    </FormLimiter>

    <FormSubmitContainer>
      <button
        className="btn btn-lg btn-primary"
        type="submit"
        disabled={submitting}
      >
        Сохранить
      </button>
    </FormSubmitContainer>
  </Form>
);
