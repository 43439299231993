import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { FaRocket } from 'react-icons/fa';

import { useAuth } from '../services/auth';

export const Header: React.FC = () => {
  const { admin, deImpersonate, logout, user } = useAuth();

  return (
    <Navbar dark color="dark" expand="md" className="mb-3">
      <NavbarBrand exact tag={Link} to="/">
        <FaRocket className="text-warning" />
      </NavbarBrand>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink tag={Link} to="/events" className="d-none d-sm-block">
            Собрания
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/meet">
            Встреча
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/staff">
            Служители
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/call">
            Обзвон
          </NavLink>
        </NavItem>
      </Nav>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <span className="d-none d-sm-inline">{user && user.name}</span>
          </DropdownToggle>
          <DropdownMenu right>
            {user?.impersonator && (
              <DropdownItem onClick={deImpersonate}>
                Войти как {user.impersonator.name}
              </DropdownItem>
            )}
            {admin && (
              <DropdownItem tag={Link} to="/users">
                Пользователи
              </DropdownItem>
            )}
            {admin && (
              <DropdownItem tag={Link} to="/app-users">
                Пользователи приложения
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={logout}>Выход</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};
