import React, { useCallback } from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { api, catchToast, useAPI } from '../services/api';

export const UpdateBotButton: React.FC = () => {
  const canUpdate = useAPI<boolean>('/events/can-update-bot');

  const onClick = useCallback(() => {
    api
      .post('/events/update-bot')
      .then(() => {
        canUpdate.refresh();
        toast.success('Сообщение отправлено');
      })
      .catch(catchToast);
  }, [canUpdate]);

  return (
    <button
      className="btn btn-outline-primary"
      disabled={canUpdate.data !== true}
      onClick={onClick}
      title="Отправить новое сообщение в чат"
      type="button"
    >
      <FaTelegramPlane />
    </button>
  );
};
