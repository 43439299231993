import React, { useCallback, useMemo } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';

import { api, catchToast, useAPI } from '../services/api';
import { CallModal } from './CallModal';
import { Event } from '../events/EventTypes';
import { EventSelect } from './EventSelect';
import { getFullName } from '../utils/format';
import { Guest } from '../guests/GuestTypes';
import { useGuests } from './useGuests';
import { useParseInt } from '../utils/usedParseInt';
import { useSearch } from '../utils/useSearch';
import { SearchInput } from '../components/SearchInput';
import { Spinner } from 'reactstrap';

export const Call: React.FC<RouteComponentProps<{ eventId: string }>> = ({
  match,
}) => {
  const events = useAPI<Event[]>('/events/current');
  const eventId = useParseInt(match.params.eventId);

  const {
    guests: data,
    updateGuest,
    setGuestId,
    guest,
    index,
    refreshing: guestsRefreshing,
  } = useGuests(eventId);

  const { items: guests, unfiltered: sorted, ...search } = useSearch({
    items: data || [],
    toSearchString: (r) =>
      getFullName(r).toLocaleLowerCase() +
      ' ' +
      r.phone.replace(/[^\d]+/g, '') +
      ' ' +
      (r.note ? r.note.toLocaleLowerCase() : ''),
    filter: (r) => !r.cancelled_at,
    sort: true,
  });

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      setGuestId(id);
    },
    [setGuestId]
  );

  const guestEvent = useMemo(
    () => guest && events.data?.find((e) => e.id === guest.event_id),
    [events, guest]
  );

  const onClose = useCallback(() => setGuestId(undefined), [setGuestId]);
  const onNext = useCallback(() => {
    const next = index === undefined ? 0 : index + 1;
    if (guests?.[next]) {
      setGuestId(guests[next].id);
    }
  }, [index, guests, setGuestId]);
  const onPrev = useCallback(() => {
    const next =
      index === undefined ? (guests ? guests.length - 1 : 0) : index - 1;
    if (guests?.[next]) {
      setGuestId(guests[next].id);
    }
  }, [index, guests, setGuestId]);

  const onConfirm = useCallback(() => {
    if (guest) {
      api
        .post<Guest>(`/guests/${guest.id}/confirm`)
        .then((res) => updateGuest(res.data))
        .catch(catchToast);
    }
  }, [updateGuest, guest]);
  const onCancel = useCallback(() => {
    if (guest) {
      api
        .post<Guest>(`/guests/${guest.id}/cancel`)
        .then((res) => updateGuest(res.data))
        .catch(catchToast);
    }
  }, [updateGuest, guest]);
  const onNote = useCallback(
    (id: number, note: string) => {
      api
        .post<Guest>(`/guests/${id}/note`, { note })
        .then((res) => updateGuest(res.data))
        .catch(catchToast);
    },
    [updateGuest]
  );

  return (
    <div>
      <CallModal
        event={guestEvent}
        guest={guest}
        hasNext={
          guests !== undefined &&
          index !== undefined &&
          index < guests.length - 1
        }
        hasPrev={index !== undefined && index > 0}
        index={(index || 0) + 1}
        onCancel={onCancel}
        onClose={onClose}
        onConfirm={onConfirm}
        onNext={onNext}
        onNote={onNote}
        onPrev={onPrev}
        total={guests?.length || 0}
      />
      <div className="d-flex flex-column mb-4">
        <h2>Обзвон</h2>

        {events.data && (
          <EventSelect
            events={events.data}
            eventId={eventId}
            urlPrefix="/call"
          />
        )}
      </div>

      {!eventId && <div>Выберите собрание</div>}

      {eventId && sorted.length > 0 && (
        <SearchInput
          onChange={search.onChange}
          onClear={search.onClear}
          value={search.value}
        />
      )}

      {eventId &&
        guests &&
        guests.length === 0 &&
        (guestsRefreshing ? (
          <Spinner color="secondary" />
        ) : (
          <p>Регистрации не найдены</p>
        ))}

      {eventId && guests && guests.length > 0 && (
        <>
          <div>
            <a
              href={`/api/guests/event/${eventId}.xlsx`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-secondary mb-4"
            >
              <FaFileExcel /> Скачать в формате Excel
            </a>
          </div>

          <div className="table-responsive d-none d-sm-block">
            <table
              className="table table-hover table-call"
              style={{ width: 'auto' }}
            >
              <thead>
                <tr>
                  <th>Фамилия</th>
                  <th>Имя</th>
                  <th>Телефон</th>
                  <th>Результат</th>
                </tr>
              </thead>
              <tbody>
                {guests.map((g) => (
                  <tr key={g.id} data-id={g.id} onClick={onRowClick}>
                    <td>{g.last_name}</td>
                    <td>{g.first_name}</td>
                    <td>{g.phone}</td>
                    <td>
                      {g.confirmed_at && (
                        <span className="badge badge-success mr-2">Придет</span>
                      )}
                      {g.cancelled_at && (
                        <span className="badge badge-secondary mr-2">
                          Не придет
                        </span>
                      )}
                      {g.note}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-block d-sm-none">
            {guests.map((g) => (
              <div
                key={g.id}
                className="py-2 call-person"
                data-id={g.id}
                onClick={onRowClick}
              >
                <div>{getFullName(g)}</div>
                <div>
                  {g.phone}{' '}
                  {g.confirmed_at && (
                    <span className="badge badge-success mr-2">Придет</span>
                  )}
                  {g.cancelled_at && (
                    <span className="badge badge-secondary mr-2">
                      Не придет
                    </span>
                  )}
                  {g.note}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
